

import React from "react";
import arrowSvg from "../images/down-arrow.svg";
import PropTypes from "prop-types";


import image from "../images/codeheadphones.png";
import logo from "../images/clogo.png";

const imageAltText = "Laptop and Headphones";

const Home = ({ name, title }) => {
  return (
    <section id="home" className="dark">
      <img className="background" src={image} alt="" />
      <div style={{ position: "absolute", top:"-7%", left: "-10rem" }}>
      <img className="logo" src={logo} alt="" />
      </div>
      <div style={{ position: "absolute", top: "30%", left: "2rem" }}>
        <h1>{name}</h1>
        <h2>{title}</h2>
      </div>
   
    
      <div className="arrowIcon" style={{ position: "absolute", bottom: "2rem", left: "48%" }}>
         <a href="#contact"  >
        <img src={arrowSvg} style={{ height: "4rem", width: "4rem" }} alt={imageAltText} />
       
           
          </a>
      </div>
    </section>
  );
};

Home.defaultProps = {
  name: "",
  title: "",
};

Home.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Home;
