

import React from "react";


import image from "../images/coding.jpg";

const imageAltText = "purple and blue abstract background";


const description =
  "I am a React Front-End Web Developer with a background in full-stack web development and design.";


const skillsList = [
  // "Tailwind",

{
    "skill_name":"ReactJs", 
    "skill_image":"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg" 
}
,
{
  "skill_name":"Javascript", 
  "skill_image":"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg"
}
,
{
  "skill_name":"Vue", 
  "skill_image":"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vuejs/vuejs-original.svg"
}
,
{
  "skill_name":"CSS", 
  "skill_image":"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-plain.svg"
}
,
{
  "skill_name":"Tailwind", 
  "skill_image":"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/tailwindcss/tailwindcss-plain.svg"
}
,
{
  "skill_name":"Python", 
  "skill_image":"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-plain.svg"
}
,
{
  "skill_name":"PSQL", 
  "skill_image":"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-plain.svg"
}
,
{
  "skill_name":"Express", 
  "skill_image":"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg"
}

];

const detailOrQuote =
  "I am passionate about solving problems creatively to drive innovation. My background in Design allows me to implement outside perspectives into front-end development projects. I am persistent and explore every avenue for success, which opens new worlds of opportunity and aids in overcoming daunting challenges. I am a professional craft beer brewer and educator who spends his free time developing recipes with innovative techniques. I am an artist consistently inspired by the beauty   and subtle intricacies of life. I always strive to create";

  const downloadResume = "";

const About = () => {
  return (
    <section className="light" id="about">
      <img className="background" src={image} alt={imageAltText} />
      <div className="whiteBackground"
        style={{
          backgroundColor: "white",
          width: "50%",
          padding: "4rem",
          margin: "3rem auto",
          textAlign: "center",
        }}
      >
        <h2>About Myself</h2>
        <p className="large">{description}</p>
        <hr />
        <div className="skillslist">
        
        
       
        
          {skillsList.map((skill) => (
            <div key={skill.skill_name} className="skillContainer">
              <img src={skill.skill_image} alt="" className=" devicon-devicon-plain" />
              <span>{skill.skill_name}</span>
           
            </div> 
          ))}
        
        </div>
        <hr />
        <p >{detailOrQuote}</p>
        
     <br></br>
     <button className="resButton">
          <a className="dres"
            href="https://drive.google.com/file/d/1RDoy_OprkcyY6f7Cnhyadl-arcRgCyB3/view?usp=sharing"
            target="_blank" 
            rel="noopener noreferrer"
            >
           
             View Resume
          </a>
          </button>
          <p className="inline-block text-lg px-8 py-4 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white">
            {downloadResume}
          </p>
      </div>
    </section>
  );
};

export default About;
