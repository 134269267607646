

import React from "react";

import image from "../images/Portpic.jpg";

const imageAltText = "portfolio Picture";


const projectList = [
  {
    title: " Go Outside Right Now",
    description:
      "An app developed to increase peoples resources when finding places to go and spend time in the outdoors.",
    url: "https://go-rn-app.herokuapp.com/",
  },
  {
    title: "The Fault In Our Stars",
    description:
      "A social app where a user can rate and review an experience whether dating or casual encounters based on zodiac signs.",
    url: "https://starz-app.herokuapp.com/",
  },
  {
    title: "IntDev Transcripts",
    description:
      "A directory service which enables students and educational institutions to view transcripts from the past and present through a 3rd party directory.",
    url: "https://intdev.herokuapp.com/",
  },
  {
    title: "CJA Amusement Park Guide",
    description:
      "An app designed to allow users to get live info on Amusement park rides, food and attractions.",
    url: "https://cja-guide.herokuapp.com/",
  },
];

const Portfolio = () => {
  return (
    <section className="light" id="portfolio">
      <h2>Portfolio</h2>
      <div style={{ paddingTop: "2rem" }}>
        <div className="portframe">
          <img className="portpic"
            src={image}
            style={{ scale: "" }}
            alt={imageAltText}
          />
        </div>
        <div className="container">
          {projectList.map((project) => (
            <div className="box" key={project.title}>
              <a href={project.url} target="_blank" rel="noopener noreferrer">
                <h3 style={{ flexBasis: "40px" }}>{project.title}</h3>
              </a>
              <p className="small">{project.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
